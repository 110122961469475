import React, { useEffect } from 'react'
import RTGLink from '@shared/link'
import { bool, string } from 'prop-types'
import { setReviewInfo } from '@helpers/checkout/review-section'
import { warrantyRegions } from '@helpers/extendedServiceContract'

const ReviewSectionWarrantyInfo = ({ isAccepted, provider }) => {
  const { isServiceContract, link: escUrl, linkTextReviewSection = 'Furniture Protection Plan Terms and Conditions' } =
    warrantyRegions[provider] ?? {}

  useEffect(() => {
    // following line is necessary, because on reload it should be false
    if (isAccepted) setReviewInfo(false, 'acceptServiceContractTerms')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="checkbox-container">
      <input
        id="furnitureProtectionPlan"
        type="checkbox"
        name="Furniture Terms and Conditions"
        checked={isAccepted}
        onChange={e => setReviewInfo(e.target.checked, 'acceptServiceContractTerms')}
      />

      <label className="text-container" htmlFor="furnitureProtectionPlan">
        <span className="terms-header">
          {`Furniture ${isServiceContract ? 'Service Contract' : 'Protection Plan'}`}
        </span>
        <span className="accept-text">By checking this box, I acknowledge that I have read and agree to the</span>

        <RTGLink
          className="accept-link"
          data={{
            target: '_blank',
            url: escUrl,
            category: 'warranty info',
            action: 'click',
            label: 'warranty text',
          }}
        >
          {linkTextReviewSection}
          <span className="hide508">(opens in new window)</span>
        </RTGLink>
        <span className="accept-text">
          &nbsp;I consent to delivery of my Service Contract, and any related documents in electronic form.
        </span>
      </label>
    </div>
  )
}

ReviewSectionWarrantyInfo.propTypes = {
  isAccepted: bool,
  provider: string,
}

export default ReviewSectionWarrantyInfo
