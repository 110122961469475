import React, { useEffect } from 'react'
import RTGLink from '@shared/link'
import { objectOf, any, bool } from 'prop-types'
import { setReviewInfo } from '@helpers/checkout/review-section'

const ReviewSectionEscConditions = ({ isAccepted, isRequired, placeOrderClicked }) => {
  const showWarranty = isRequired.acceptSevenYearTerms !== undefined
  const contract = showWarranty && isRequired.acceptSevenYearTerms

  const termsHeader =
    contract === 'FORTEGRA' ? 'Lifestyle Adjustable Base Service Contract' : 'Lifestyle Adjustable Base Protection Plan'
  const contractTitle =
    contract === 'FORTEGRA'
      ? 'Lifestyle Adjustable Base Service Contract by Fortegra'
      : `Residential Lifestyle Adjustable Base Protection Plan by ${contract === 'EXTEND' ? 'Extend' : 'Guardian'}`
  const acceptText = `I am purchasing the seven (7) year Guard It ${contractTitle}, and I have read and agree to the terms and conditions of that `
  const escConditions = `${termsHeader} including the Dispute Resolution/Arbitration Agreement and Class Action Waiver. `
  const escContinue = `Further, I consent to delivery of my ${termsHeader}, and any related documents, in electronic form.`
  const escLink =
    contract === 'FORTEGRA'
      ? 'https://legal.roomstogo.com/rtg-online#contract-hkhqrdpni'
      : 'https://legal.roomstogo.com/#contract-rj2qspmgd'

  useEffect(() => {
    // following line is necessary, because on relaod it should be false
    if (isAccepted) setReviewInfo(false, 'acceptSevenYearTerms')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showWarranty && (
        <div className="checkbox-container">
          <input
            style={{ border: !isAccepted && placeOrderClicked && '1px solid red' }}
            id="extendedServiceContract"
            type="checkbox"
            name="Accept Extended Service Contract"
            checked={isAccepted}
            onChange={e => setReviewInfo(e.target.checked, 'acceptSevenYearTerms')}
          />
          <label htmlFor="extendedServiceContract" className="text-container">
            <span className="terms-header">{termsHeader}</span>
            <span className="accept-text">{acceptText}</span>

            <RTGLink
              className="accept-link"
              data={{
                target: '_blank',
                url: escLink,
                category: 'extended service contract',
                action: 'click',
                label: 'conditions',
              }}
            >
              {escConditions}
              <span className="hide508">(opens in new window)</span>
            </RTGLink>
            <span className="accept-text">{escContinue}</span>
          </label>
        </div>
      )}
    </>
  )
}

ReviewSectionEscConditions.propTypes = {
  isAccepted: bool,
  isRequired: objectOf(any),
  placeOrderClicked: bool,
}

export default ReviewSectionEscConditions
