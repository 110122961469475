import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Recaptcha from 'react-recaptcha'
import useRecaptchaLoader from '@helpers/hooks/useRecaptchaLoader'
import { setCheckoutStep } from '@helpers/checkout/global'
import { Button } from '@mui/material'
import loaderLight from '../../../../assets/images/loader-light.svg'

const isSpaceOrEnterKey = key => [' ', 'Enter', 'NumpadEnter', 'Spacebar'].includes(key)

const SITE_KEY = process.env.GATSBY_RECAPTCHA_SITEKEY

const PlaceOrderButton = ({ isDisabled, isLoading, isMobile, handleTerms }) => {
  const { isWindow, isScriptLoaded } = useRecaptchaLoader()
  const recaptchaRef = useRef(null)

  useEffect(() => {
    if (isScriptLoaded && isMobile) {
      // TODO - Temporary hack to hide the recaptcha badge since it overlaps the Place Order button when Mobile OrderSummary drawer is open.
      // This needs to be fixed since Google requires the badge to be displayed - need to modify CSS of the badge to get it to display
      // at the top of the Swipeable Drawer. More info: https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge
      const recaptchaBadge = document.getElementById('g-recaptcha')
      if (recaptchaBadge) recaptchaBadge.style.visibility = 'hidden'
    }
  })

  const handleButton = async event => {
    handleTerms()
    event.stopPropagation()
    if (event.type === 'keydown' && !isSpaceOrEnterKey(event.key)) return null
    return recaptchaRef.current.execute()
  }

  const verifyCallback = async token => {
    if (token) {
      setCheckoutStep(null, 'review', undefined, true, token)
    }
  }

  return (
    <>
      {isWindow && isScriptLoaded && (
        <Recaptcha
          ref={recaptchaRef}
          render="explicit"
          sitekey={SITE_KEY}
          size="invisible"
          verifyCallback={verifyCallback}
        />
      )}
      <Button
        className="place-order-btn" // not for styling; used as a FullStory selector
        disabled={isDisabled}
        onClick={!isLoading ? handleButton : null}
        onKeyDown={!isLoading ? handleButton : null}
        sx={
          isMobile
            ? {
                marginTop: '12px !important',
                '&.Mui-disabled': {
                  color: 'black',
                },
              }
            : {
                width: '192px',
                '&.Mui-disabled': {
                  color: 'black',
                },
              }
        }
        variant="contained"
        fullWidth={isMobile}
      >
        {isLoading ? (
          <img style={{ height: 28 }} alt="Placing order" src={loaderLight} />
        ) : (
          <>{isMobile ? 'Place Order' : 'Place your order'}</>
        )}
      </Button>
    </>
  )
}

PlaceOrderButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleTerms: PropTypes.func,
}

export default PlaceOrderButton
