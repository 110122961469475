import React, { useEffect, useState, useContext } from 'react'
import { object } from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTest } from '@hooks/useTest'
import { styled } from '@mui/material'
import { useOrderSummary } from '../../cart/useOrderSummary'
import DesktopSummary from '../../../@rtg2022/components/organisms/Checkout/OrderSummary/Desktop'
import MobileSummary from '../../../@rtg2022/components/organisms/Checkout/OrderSummary/Mobile'
import PlaceOrderContext from './checkout-steps/placeOrderContext'

// Alerts the user to which values have not been populated based on current Checkout step
const getMissingDataMessage = (hasDelivery, hasSalesTax) => {
  if (!hasDelivery && !hasSalesTax) return '*Total does not include sales tax or delivery'
  if (hasDelivery && !hasSalesTax) return '*Total does not include sales tax'
  if (!hasDelivery && hasSalesTax) return '*Total does not include delivery'
  return null
}
const DesktopWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const MobileWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const OrderSummaryWrapper = ({ order }) => {
  const [shippingPrice, setShippingPrice] = useState('--')
  const { generateTestId } = useTest()
  const dtIdPrefix = generateTestId('Checkout OrderSummary', '')
  const {
    balanceInfo,
    checkoutStep,
    checkoutStepsCompleted,
    completedDeliveryStep,
    completedShippingStep,
    deliveryCost,
    includesExpressFee,
    lineItems,
    loading,
    paymentInfo,
    shippingType,
    subtotal,
    tax,
    total,
  } = useOrderSummary()
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { isClicked } = useContext(PlaceOrderContext)
  const hasShipping = completedDeliveryStep || (!!shippingType && (deliveryCost || deliveryCost === 0))
  const isShippingStep = checkoutStep === 'shipping'
  const hasPaymentInfo = paymentInfo?.length > 0

  useEffect(() => {
    if (completedShippingStep || deliveryCost) {
      setShippingPrice(hasShipping || hasPaymentInfo ? deliveryCost : '--')
    } else {
      setShippingPrice('--')
    }
  }, [completedShippingStep, deliveryCost, hasPaymentInfo, hasShipping])

  const isVendorOnlyOrder = order?.lineItems?.every(item => ['O', 'T', 'U'].includes(item.deliveryType))
  const hasDelivery =
    isVendorOnlyOrder || hasPaymentInfo || completedDeliveryStep || (!!shippingType && completedShippingStep)

  const missingDataMessage = getMissingDataMessage(hasDelivery, completedDeliveryStep || hasPaymentInfo)

  const totalLineItems = [
    { subheading: 'Subtotal', price: subtotal },
    {
      subheading: `Shipping & Delivery${includesExpressFee && !isShippingStep && shippingPrice !== '--' ? ' **' : ''}`,
      price: isShippingStep && !hasPaymentInfo ? '--' : shippingPrice,
    },
    { subheading: 'Sales Tax', price: checkoutStepsCompleted?.delivery || hasPaymentInfo ? tax : '--' },
  ]

  if (balanceInfo?.totalSavings) {
    totalLineItems.splice(1, 0, { isDiscount: true, subheading: 'Offers Applied', price: balanceInfo.totalSavings })
  }

  return (
    <>
      {isDesktop ? (
        <DesktopSummary
          balanceInfo={balanceInfo}
          dtIdPrefix={dtIdPrefix}
          loading={loading}
          missingDataMessage={missingDataMessage}
          productLineItems={lineItems}
          total={total}
          totalLineItems={totalLineItems}
        />
      ) : (
        <MobileSummary
          balanceInfo={balanceInfo}
          dtIdPrefix={dtIdPrefix}
          isShippingStep={isShippingStep}
          loading={loading}
          missingDataMessage={missingDataMessage}
          order={order}
          placeOrderClicked={isClicked}
          productLineItems={lineItems}
          showContinueBtn={checkoutStep !== 'review'}
          total={total}
          totalLineItems={totalLineItems}
        />
      )}
    </>
  )
}

OrderSummaryWrapper.propTypes = {
  order: object,
}

export default OrderSummaryWrapper
