import React, { useEffect } from 'react'
import RTGLink from '@shared/link'
import { bool } from 'prop-types'
import { setReviewInfo } from '@helpers/checkout/review-section'
import onKeyDownCheck from '@helpers/508/onkeydown-check'
import { useTest } from '@hooks/useTest'
import { acceptText, termsAndConditions, termsOfUse } from './constants'

const ReviewSectionTermsConditions = ({ acceptTerms, placeOrderClicked }) => {
  const { generateTestId } = useTest()
  const handleKeyDown = e => {
    // input was clicked
    if (e.type === 'change') {
      setReviewInfo(e.target.checked, 'acceptTerms')
    }
    // Enter key pressed on link
    if (onKeyDownCheck(e.keyCode)) {
      window.open(e.nativeEvent.target.href, '_blank')
    }
  }

  useEffect(() => {
    // following line is necessary, because on relaod it should be false
    if (acceptTerms) setReviewInfo(false, 'acceptTerms')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="checkbox-container">
      <input
        style={{ border: !acceptTerms && placeOrderClicked && '1px solid red' }}
        id="termsAndConditions"
        type="checkbox"
        name="Accept Terms and Conditions"
        checked={acceptTerms}
        onChange={e => handleKeyDown(e)}
        data-testid={generateTestId('checkout', 'review-terms-and-conditions-checkbox')}
      />
      <label htmlFor="termsAndConditions" className="text-container">
        <span className="accept-text">{acceptText}</span>
        <RTGLink
          className="accept-link"
          data={{
            target: '_blank',
            url: 'https://legal.roomstogo.com/rtg-online#contract-h1qbyyz5u',
            category: 'extended service contract',
            action: 'click',
            label: 'conditions',
          }}
        >
          {termsAndConditions},<span className="hide508">(opens in new window)</span>
        </RTGLink>
        <RTGLink
          className="accept-link"
          data={{
            target: '_blank',
            url: 'https://legal.roomstogo.com/rtg-online#contract-fxauo3tp2',
            category: 'extended service contract',
            action: 'click',
            label: 'privacy notice',
          }}
        >
          Privacy Notice,
          <span className="hide508">(opens in new window)</span>
        </RTGLink>
        <span className="accept-text">{` `}</span>
        <span className="accept-text">and</span>
        <RTGLink
          className="accept-link"
          data={{
            target: '_blank',
            url: 'https://legal.roomstogo.com/rtg-online#contract-7siltltgm',
            category: 'extended service contract',
            action: 'click',
            label: 'terms of use',
          }}
        >
          {termsOfUse}
          <span className="hide508">(opens in new window)</span>
        </RTGLink>
        .
      </label>
    </div>
  )
}

ReviewSectionTermsConditions.propTypes = {
  acceptTerms: bool,
  placeOrderClicked: bool,
}

export default ReviewSectionTermsConditions
