// dependencies
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// helpers
import { getSubtotalDisplayValue, getTotalDisplayValue } from '@helpers/order'
import { getCartTotal } from '@helpers/cart'
import { getRemainingTotal } from '@helpers/checkout/global'
import { getRegionZone } from '@helpers/geo-location'
import { productPrice, getWarrantyDataByRegionZone, getProductDisplayImage } from '@helpers/product'

const GIFT_CARD_SKU = '83333333'

const aggLineItems = ({ cartLineItems, orderLineItems, storeCartLineItems }) => {
  const itemsSkus = {
    onlineItems: cartLineItems.map(item => item?.sku),
    storeItems: storeCartLineItems.map(item => item?.sku),
  }

  const decoratedItems = []
  const filteredOrderLineItems = orderLineItems.filter(item =>
    (item?.isStoreSku ? itemsSkus.storeItems : itemsSkus.onlineItems)?.includes?.(item.sku),
  )

  filteredOrderLineItems.forEach((item, i) => {
    const cartItems = (item?.isStoreSku ? storeCartLineItems : cartLineItems)?.filter(x => {
      if (x.sku === GIFT_CARD_SKU) {
        return x.sku === item.sku && x?.price === item?.unitPrice
      }
      return x.sku === item.sku
    }) ?? [item]

    cartItems.forEach(cartItem => {
      const activeAddons = cartItem?.activeAddons ?? []
      const price = cartItem?.product ? productPrice(cartItem?.product) : item.unitPrice ?? (cartItem.price || 0)
      const itemTitle = item?.title ?? (cartItem?.product?.title || '')
      const { region } = getRegionZone()

      const couponApplied =
        (!!cartItem.product?.couponDisplayData || !!cartItem.product?.couponDisplayFields?.[`${region}`]) &&
        (cartLineItems.some(_cartItem => _cartItem?.promotion?.promotionItemSku === cartItem.sku) ||
          !cartItem.product.promotions)

      // After items are agg'd, makes sure duplicate sku's don't get pushed, except for Gift Card SKUs with different prices
      if (item.sku === GIFT_CARD_SKU || !decoratedItems.some(lineItem => lineItem.sku === item.sku))
        decoratedItems.push({
          addOns: cartItem?.selections?.sku ? [cartItem.selections, ...activeAddons] : activeAddons,
          id: `summary_line_item_${i}`,
          image: cartItem?.product ? getProductDisplayImage(cartItem.product ?? {}) : cartItem?.imageUrl,
          price: cartItem?.product?.bonusItemSavings ? cartItem.price : price,
          protectionPlanPrice: cartItem?.product
            ? cartItem?.product?.warrantyPrice?.price ??
              getWarrantyDataByRegionZone(cartItem.product?.warranty_pricing, getRegionZone())?.price
            : item?.warrantyPrice?.price || 0,
          protectionPlanProvider: item?.warrantyPrice?.provider ?? '',
          protectionPlanSelected: item?.warrantyEnabled ?? false,
          couponApplied,
          quantity: cartItem?.quantity || item?.quantity,
          sku: item?.sku,
          title: item.sku === GIFT_CARD_SKU ? `${itemTitle} - $${price}` : itemTitle,
          savings: item?.additionalOption ? item?.additionalOptionPrice?.savings : 0,
        })
    })
  })

  return decoratedItems
}

// eslint-disable-next-line import/prefer-default-export
export const useOrderSummary = () => {
  const [orderData, setOrderData] = useState({})
  const cart = useSelector(state => state.cart.cart)
  const { checkoutStepsCompleted, deliveryMode, order, checkoutStep } = useSelector(state => state.checkout)

  useEffect(() => {
    const decoratedLineItems = aggLineItems({
      cartLineItems: cart?.cartItems ?? [],
      orderLineItems: order?.lineItems ?? [],
      storeCartLineItems: order?.storeCart?.lineItems ?? [],
    })

    const subtotal = getSubtotalDisplayValue(order, cart, order?.storeCart?.lineItems)
    const cartTotal = getCartTotal(cart)

    const { gift, fin, tax, deliveryCost, totalSavings } = getRemainingTotal(
      checkoutStepsCompleted && checkoutStepsCompleted.delivery,
      cartTotal,
      true,
      checkoutStepsCompleted && checkoutStepsCompleted.shipping,
      checkoutStep !== 'shipping',
    )
    const total = getTotalDisplayValue(
      order,
      true,
      checkoutStepsCompleted,
      cart,
      order?.storeCart?.lineItems || order?.storeCart?.lineItems[0]?.childItems,
      deliveryMode,
    )
    const affirmAppliedBalance = order?.paymentInfo?.filter(payment => payment.paymentType === 'AFF')[0]
      ?.authorizedAmount
    const klarnaAppliedBalance = order?.paymentInfo?.filter(payment => payment.paymentType === 'KLN')[0]
      ?.authorizedAmount
    const hasGenesisPlan = !!(
      order.paymentInfo &&
      order.paymentInfo.length &&
      order.paymentInfo.filter(plan => plan.paymentType === 'GEN')[0]
    )

    let hasExpressSplitDelivery = false
    const expressSplitDelivery =
      !order.isPickup && order?.splitDeliveryDates?.find(delivery => delivery.expressDelivery)
    if (expressSplitDelivery) {
      hasExpressSplitDelivery = order.splitCalendar.some(
        dt => dt.deliveryCalendar.expressDeliveryDate === expressSplitDelivery.deliveryDate,
      )
    }

    const includesExpressFee =
      hasExpressSplitDelivery || (order.isExpress && order?.expressDeliveryDate === order.deliveryDate)

    const hasExpressFeeAvailable =
      (order?.expressCalendar?.[0] || hasExpressSplitDelivery) && order?.premiumExpressFee > 0

    const expressFee = hasExpressFeeAvailable && order?.premiumExpressFee ? order?.premiumExpressFee : 0

    setOrderData({
      amtDue: order?.amountDue,
      balanceInfo: {
        // This is all payment applied info, etc
        affirmAppliedBalance,
        fin,
        gift,
        hasGenesisPlan,
        klarnaAppliedBalance,
        totalSavings,
      },
      checkoutStep,
      checkoutStepsCompleted,
      completedDeliveryStep: checkoutStepsCompleted.delivery,
      completedShippingStep: checkoutStepsCompleted.shipping,
      deliveryCost,
      includesExpressFee,
      expressFee,
      lineItems: decoratedLineItems,
      loading: decoratedLineItems?.length <= 0,
      paymentInfo: order.paymentInfo,
      shippingType: deliveryMode,
      subtotal,
      tax,
      total: order?.paymentInfo?.length ? order.total : total,
    })
  }, [cart, checkoutStep, checkoutStepsCompleted, deliveryMode, order])

  return orderData
}
